/* eslint-disable no-console */
import { takeLatest, put } from 'redux-saga/effects';
import * as TypeActions from '../store/usuario/actionsTypes';
import UsuarioService from '../services/UsuarioService';
import BaseService from '../services/BaseService';
import FileService from '../services/FileService';
import * as ToastTypeActions from '../store/toast/actionsTypes';
import * as LoaderTypeActions from '../store/loader/actionsTypes';

function* loadUsuarios({ filter }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.post({ url: 'usuarios/filtro', data: filter });
    yield put({
      type: TypeActions.FETCH_USERS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* findOptions({ grupo, ativo }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    let url = 'usuarios/roles';
    
    if (grupo) {
      url = `${url}?roles=${grupo}`;
    }

    if (ativo && url.indexOf('?') > 0) {
      url = `${url}&ativo=${ativo}`;
    } else if (ativo) {
      url = `${url}?ativo=${ativo}`;
    }

    const response = yield BaseService.get({ url });
    yield put({
      type: TypeActions.USUARIO_FIND_OPTIONS_SUCCESS,
      data: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* findInspetoresOptions() {
  try {
    const response = yield BaseService.get({ url: 'usuarios/inspetores' });
    yield put({
      type: TypeActions.USUARIO_FIND_INSPETORES_OPTIONS_SUCCESS,
      data: response.data
    });
  } catch (error) {
    yield console.log(error);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* findDigitadoresOptions() {
  try {
    const response = yield BaseService.get({ url: 'usuarios/digitadores' });
    yield put({
      type: TypeActions.USUARIO_FIND_DIGITADORES_OPTIONS_SUCCESS,
      data: response.data
    });
  } catch (error) {
    yield console.log(error);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao carregar as informações!'
    });
  }
}

function* save({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });

    let usuario = data;
    if (data.documentos && data.documentos.length > 0) {
      usuario = {
        ...usuario,
        documentos: data.documentos.map(d => {
          return {
            ...d,
            arquivos: FileService.getArquivosToUpload(d.documentos)
          }
        })
      }
    }

    const response = yield BaseService.post({ url: 'usuarios', usuario });
    if (response.data && response.data.documentos && response.data.documentos.length > 0) {
      const arquivoToUpload = data.documentos.flatMap(d => d.arquivos);
      const arquivosBackend = response.data.documentos.flatMap(d => d.arquivos);
      yield FileService.uploadArquivos(arquivoToUpload, arquivosBackend);
    }

    yield put({
      type: TypeActions.SAVE_USER_SUCCESS,
      data: response.data
    });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Usuário salvo com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = "Erro ao salvar o usuário!";
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    }
    
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* update({ data, callback }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    let usuario = data;
    if (data.documentos && data.documentos.length > 0) {
      usuario = {
        ...usuario,
        documentos: data.documentos.map(d => {
          return {
            ...d,
            arquivos: FileService.getArquivosToUpload(d.arquivos)
          }
        })
      }
    }

    const response = yield BaseService.put({ url: `usuarios/${data.id}`, data: usuario });
    if (response.data && response.data.documentos && response.data.documentos.length > 0) {
      const arquivoToUpload = data.documentos.flatMap(d => d.arquivos);
      const arquivosBackend = response.data.documentos.flatMap(d => d.arquivos);
      yield FileService.uploadArquivos(arquivoToUpload, arquivosBackend);
    }

    yield put({
      type: TypeActions.UPDATE_USER_SUCCESS,
      data: response.data
    });
    yield callback(true);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Usuário salvo com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    let message = 'Erro ao salvar o usuário!';
    if (error.response && error.response.status === 409) {
      message = error.response.data.mensagem;
      yield callback(false);
    } 

    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message
    });
  }
}

function* deleteUsuario({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield UsuarioService.delete(id);
    yield put({
      type: TypeActions.DELETE_USER_SUCCESS,
      id
    });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Usuário deletado com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao deletar o usuário!'
    });
  }
}

function* findUsuarioById({ id }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield UsuarioService.findById(id);
    const documentos = [];
    if (response.data && response.data.documentos && response.data.documentos.length > 0) {
      for (let documento of response.data.documentos) {
        documentos.push({
          ...documento,
          arquivos: yield FileService.downloadArquivos(documento.arquivos),
        });
      }
    }

    yield put({
      type: TypeActions.USUARIO_FIND_BY_ID_SUCCESS,
      data: {
        ...response.data,
        documentos
      }
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao recuperar o usuário!'
    });
  }
}

function* resetPassword({ usuarioId }) {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    yield UsuarioService.resetPassword(usuarioId);
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_SUCCESS,
      message: 'Senha resetada com sucesso!'
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao resetar a senha!'
    });
  }
}

function* proximaMatricula() {
  try {
    yield put({ type: LoaderTypeActions.LOADER_SHOW });
    const response = yield BaseService.get({ url: 'usuarios/matricula' });
    yield put({
      type: TypeActions.USUARIO_PROXIMA_MATRICULA_SUCCESS,
      proximaMatricula: response.data
    });
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
  } catch (error) {
    yield console.log(error);
    yield put({ type: LoaderTypeActions.LOADER_HIDE });
    yield put({
      type: ToastTypeActions.TOAST_MESSAGE_ERROR,
      message: 'Erro ao calcular a próxima matricula!'
    });
  }
}

export default function* watchAuth() {
  yield takeLatest(TypeActions.FETCH_USERS, loadUsuarios);
  yield takeLatest(TypeActions.USUARIO_FIND_OPTIONS, findOptions);
  yield takeLatest(TypeActions.SAVE_USER, save);
  yield takeLatest(TypeActions.UPDATE_USER, update);
  yield takeLatest(TypeActions.DELETE_USER, deleteUsuario);
  yield takeLatest(TypeActions.USUARIO_FIND_BY_ID, findUsuarioById);
  yield takeLatest(TypeActions.USUARIO_FIND_INSPETORES_OPTIONS, findInspetoresOptions);
  yield takeLatest(TypeActions.USUARIO_FIND_DIGITADORES_OPTIONS, findDigitadoresOptions);
  yield takeLatest(TypeActions.USUARIO_RESET_PASSWORD, resetPassword);
  yield takeLatest(TypeActions.USUARIO_PROXIMA_MATRICULA, proximaMatricula);
}